<template>
    <h6 style="font-weight: bolder; color: black">Answer</h6>
    <div v-html="essayData.answerText"></div>
    <div class="row">
        <div class="col-sm-4">
            <span style="font-weight: bold; color: black">Approve Answer?</span>
            <div class="row">
                <div class="col-sm-2">
                    <b-tooltip target="_self" title="Deny this answer">
                        <button class="bi bi-hand-thumbs-down-fill btn-danger rounded-circle"
                                @click="setApproved(false)" alt="approve"
                                style="border-radius: 100%; height: 34px; width: 34px;"></button>
                    </b-tooltip>
                </div>
                <div class="col-sm-2">
                    <b-tooltip target="_self" title="Approve this answer">
                        <button class="bi bi-hand-thumbs-up-fill btn-primary rounded-circle" @click="setApproved(true)"
                                alt="approve" style="border-radius: 100%; height: 34px; width: 34px;"></button>
                    </b-tooltip>
                </div>
            </div>
        </div>

    </div>
    <div>
        <br/>
        <h6 style="font-style: italic">Notes
            <span>
                <b-tooltip target="_self"
                           title="If denying an essay answer, you must include notes. You may still include notes even if approving." triggers="hover">
                    <i class="bi-info-circle"></i>
                </b-tooltip>
            </span>
        </h6>
        <div :style="validatedNotesForm ? `border: 3px solid green;` : `border: 3px solid red;`">
            <QuillEditor v-model:content="essayUpdateBody.notesText"
                         content-type="html"
                         theme="snow"
                         toolbar="essential"
                         style="min-height: 150px; max-height: 150px; overflow-y: scroll;"
                         contenteditable="true"
                         @update:content="setNotes"
            />
        </div>
        <div v-if="essayData.essayNotes.length > 0" style="font-style: italic; margin-top: 1%">
            <h6 style="font-weight: bold">Notes History</h6>
            <hr/>
            <div v-for="(note, noteIdx) in essayData.essayNotes" :key="noteIdx">
                <div v-if="!note.activelyEditing">
                    <span style="font-weight: bold; font-style: normal; color: black;">Note By: {{
                        note.notesCreatedBy
                        }} | Note Created: {{ note.notesCreatedOn }}</span>
                    <div v-html="note.notesText"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, onBeforeMount, computed} from "vue";
import axios from "axios";
import {saveNotesNoSubmit, updateEssay} from '@/Helpers/ApiCalls/EssayAPICalls';
import store from "@/store";
import Swal from "sweetalert2";
import {QuillEditor} from "@vueup/vue-quill";

export default {
	name: "EssayReviewComponent",
	components: {QuillEditor},
	props: {
		essayData: {
			type: Object,
			required: true,
		},
		idGrade: {
			type: Number,
			required: true,
		},
		updatingUserId: {
			type: Number,
			required: true,
		},
		questionText: {
			type: String,
		},
	},
	setup(props, context) {
		const authToken = store.getters['auth/authenticated'];
		const essayUpdateBody = ref({
			idEssayAnswer: props.essayData.idAnswer,
			approvedEssay: false,
			updatingUserId: props.updatingUserId,
			notesText: "",
			essayText: props.essayData.answerText,
			questionText: props.questionText,
			activelyEditing: true,
			idNotes: null,
		})

		onBeforeMount(() => {
			let tmp_notes = props.essayData.essayNotes;
			for (let i = 0; i < tmp_notes.length; i++) {
				if (tmp_notes[i].activelyEditing) {
					essayUpdateBody.value.notesText = tmp_notes[i].notesText;
					essayUpdateBody.value.idNotes = tmp_notes[i].idNotes;
					setNotes();
				}
			}
			// essayUpdateBody.value.notesText = tmp_notes.filter((en) => en.activelyEditing === true).get(0);
			// console.log(essayUpdateBody.value.notesText);
			// console.log(props.essayData);
		})

		function setApproved(value) {
			essayUpdateBody.value.approvedEssay = value;
			context.emit("essayUpdate", essayUpdateBody.value);
			validateForm();
		}

		function setNotes() {
			context.emit("essayUpdate", essayUpdateBody.value);
			validateForm();
		}

		function validateForm() {
			context.emit("validateForm", validatedNotesForm.value);
		}

		// async function autoSaveEditingNote() {
		// 	await axios.post(saveNotesNoSubmit(props.essayData.idAnswer), essayUpdateBody.value, {
		// 		headers: {
		// 			"Authorization": `Bearer ${authToken.value}`
		// 		}
		// 	}).then((result) => {
		// 	    essayUpdateBody.value.idNotes = result.data.idEssayNote;
		// 	})
		// }

		const validatedNotesForm = computed(() => {
			return essayUpdateBody.value.approvedEssay ? true : (essayUpdateBody.value.notesText !== "" && essayUpdateBody.value.notesText !== "<p><br></p>");
		})


		return {
			essayUpdateBody,
			validatedNotesForm,
			setApproved,
			setNotes,
			// autoSaveEditingNote,
		}
	}
}
</script>

<style scoped>

</style>