import {API_URL} from "../../../Constants";

export const updateEssay = () => {
	return `${API_URL}/essay/update`;
}

export const getEssayById = (idEssay) => {
	return `${API_URL}/essay/byId/${idEssay}`;
}

export const updateEssaysForQuizAndUser = (idUser, idQuiz) => {
	return `${API_URL}/essay/updateByUser/${idUser}/quiz/${idQuiz}`;
}

export const saveNotesNoSubmit = (idEssay) => {
	return `${API_URL}/essay/saveNotes/${idEssay}`;
}

export const saveOrUpdateEditingNotesInList = () => {
	return `${API_URL}/essay/saveNotes/list`;
}