<template>
    <div class="container-fluid">
        <h2>Question:</h2>
        <div v-html="essayData.questionText">
        </div>
        <h3>{{essayData.userName}}'s Answer</h3>
        <div v-html="essayData.essayText"></div>
    </div>
</template>

<script>
import {ref, onBeforeMount} from "vue";
import axios from "axios";
import {getEssayById} from "@/Helpers/ApiCalls/EssayAPICalls";
import store from "@/store";
import EssayReviewComponent from "@/components/Essay/EssayReviewComponent.vue";

export default {
    name: "EssayReviewView",
    // eslint-disable-next-line vue/no-unused-components
    components: {EssayReviewComponent},
    props: {
        idGrade: {
            type: Number,
            required: true,
        },
        idEssayAnswer: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        const authToken = store.getters['auth/authenticated'];
        const essayData = ref({});

        onBeforeMount(() => {
            getEssay();
        })

        async function getEssay() {
            await axios.get(getEssayById(props.idEssayAnswer), {
                headers: {
                    "Authorization": `Bearer ${authToken.value}`
                }
            }).then((result) => {
                essayData.value = result.data;
            })
        }

        return {
            essayData
        }
    }
}
</script>

<style scoped>

</style>